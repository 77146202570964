import React, { useEffect, Fragment, useState } from "react"
import NumberFormat from "react-number-format"
import InputRange from "react-input-range"
import "react-input-range/lib/css/index.css"
import { navigate } from "gatsby"
import { scrollToElement } from "../../../utility/utils"
import CTAInvestments from "../general/ctaInvestments"
import Button from "../general/Button"
import Header from "../scam/components/header"
import Dropdown from "./Dropdown"
import FAQList from "../general/faqsList"
import { formatBalance } from "../../../../util/util"
import LoanSummaryIcon from "../../../../assets/img/kuda-loan.inline.svg"
import ScrollButton from "./sroll-button"
import {
  InputSectionWrap,
  LoanCalcWrap,
  LoanDetails,
  OutputWrap,
  SummaryDetailsWrap,
} from "./personal-loans-styles"

const PersonalLoanCalculator = () => {
  const [loanAmount, setLoanAmount] = useState(100000)
  const [interestRate, setInterestRate] = useState(9)
  const [loanTerm, setLoanTerm] = useState(1)
  const [repayments, setRepayments] = useState(1)
  const [monthlyInterest, setMonthlyInterest] = useState(9000)
  const [totalInterest, setTotalInterest] = useState(27000)
  const [totalRepayable, setTotalRepayable] = useState(127000.0)
  const [monthlyRepayment, setMonthlyRepayment] = useState(42333)
  const [loanLimit, setLoanLimt] = useState("250,000")

  const entryContent = {
    title: `Loan Calculator`,
    subtitle:
      "Loans are easier to repay when you plan properly. Set a loan amount and your preferred repayment time below to see the estimated amount you’ll repay monthly if you take a Kuda Loan.",
  }

  const questions = [
    {
      heading: "How do I use the loan calculator?",
      list: (
        <span className="flex flex-column">
          <span>
            Just set the amount you’d like to borrow and how long you’d like to
            borrow for. The calculator will show you the interest rate, the
            actual amount of interest you’ll pay, and your monthly repayment for
            the duration of the loan.
          </span>
        </span>
      ),
      index: 1,
    },
    {
      heading: "Why should I use a loan calculator?",
      list: (
        <span className="flex flex-column">
          <span>
            A loan calculator makes it easy for you to see how much you’ll repay
            if you take a loan. This will help you to compare loans at different
            interest rates, decide if you should take a loan or not, and plan
            your finances so you can repay a loan conveniently.
          </span>
        </span>
      ),
      index: 2,
    },
  ]

  const termOptions = [
    { value: "1", label: "1 Month" },
  ]

  const adjustFiguresForAmt = () => {
    setMonthlyInterest(0)
    setTotalInterest(0)
    setTotalRepayable(0)
    setMonthlyRepayment(0)
    setRepayments(loanTerm)
    const interest = (interestRate / 100) * loanAmount
    const totalInterest = interest * loanTerm
    const totalRepayable = totalInterest + loanAmount
    const value = totalRepayable / loanTerm
    setMonthlyInterest(Number(interest).toFixed(2))
    setTotalInterest(Number(totalInterest).toFixed(2))
    setTotalRepayable(Number(totalRepayable).toFixed(2))
    setMonthlyRepayment(Number(value).toFixed(2))
  }

  const handleDropdownChange = value => {
    setMonthlyInterest(0)
    setTotalInterest(0)
    setTotalRepayable(0)
    setMonthlyRepayment(0)
    setRepayments(0)
    const interest = (interestRate / 100) * loanAmount
    const totalInterest = interest * value
    const totalRepayable = totalInterest + loanAmount
    const monthlyCost = totalRepayable / value
    setRepayments(value)
    setMonthlyInterest(Number(interest).toFixed(2))
    setTotalInterest(Number(totalInterest).toFixed(2))
    setTotalRepayable(Number(totalRepayable).toFixed(2))
    setMonthlyRepayment(Number(monthlyCost).toFixed(2))
  }

  const formatNumber = value => {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value)
  }

  const handleNextClick = () => {
    navigate(`/joinKuda/`)
  }

  async function getFirebaseData(firebase) {
    const data = await firebase.FireBase()
    const firebaseTermLoans = firebase.getFirebaseTermLoans()
    if (firebaseTermLoans?.loanLimit) {
      const newValue = formatBalance(firebaseTermLoans?.loanLimit, 0)
      setLoanLimt(newValue)
    }
    return data
  }

  useEffect(() => {
    scrollToElement()
    window.addEventListener("scroll", function () {
      scrollToElement()
    })
    import("../../../../firebase").then(firebase => {
      getFirebaseData(firebase)
    })
  }, [])

  return (
    <Fragment>
      <Header title={entryContent.title} subtitle={entryContent.subtitle} />
      <LoanCalcWrap>
        <div className=" flex justify-between flex-wrap">
          <InputSectionWrap className="kuda-single--protection">
            <ScrollButton />
            <div className="pb-4 color-primary text-xlbold text-center repayment-h3">
              Calculate Repayment
            </div>
            <div className="card-group">
              <label className="card-label color-black">
                How much would you like to borrow?
              </label>
              <div className="add-amount">
                <span className="denomination">₦</span>
                <NumberFormat
                  thousandSeparator={true}
                  type="tel"
                  name="amount"
                  placeholder="100,000.00"
                  value={formatNumber(loanAmount)}
                  className="amount-input"
                  readOnly={true}
                />
              </div>
            </div>

            <div className="mt-5 mb-3">
              <InputRange
                maxValue={250000}
                minValue={0}
                value={loanAmount}
                step={1000}
                onChange={loanAmount => setLoanAmount(loanAmount)}
                onChangeComplete={adjustFiguresForAmt}
                formatLabel={loanAmount => `₦${loanAmount.toLocaleString()}`}
              />
            </div>

            <div className="card-group mt-5" id="loan-prop">
              <label className="card-label color-black">
                How much time would you need to repay the loan?
              </label>
              <div className="add-amount">
                <Dropdown
                  options={termOptions}
                  defaultValue="1"
                  onChange={handleDropdownChange}
                />
              </div>
            </div>
          </InputSectionWrap>

          <OutputWrap className="kuda-single--protection">
            <div id="calculations" className="text-center">
              <p className="loan-icon narration">
                <span className="">
                  <LoanSummaryIcon />
                </span>{" "}
                Total Repayment
              </p>
              <h2 className="text-xbold mb-4">
                ₦{formatNumber(totalRepayable)}
              </h2>
              <p className="mb-4 narration">
                If you take a loan of{" "}
                <span className="emphasized-amts">
                  ₦{formatNumber(loanAmount)}
                </span>{" "}
                at a monthly interest rate of{" "}
                <span className="emphasized-amts">{interestRate}%</span>, you'll
                repay{" "}
                <span className="emphasized-amts">
                  ₦{formatNumber(monthlyRepayment)}
                </span>{" "}
                monthly for{" "}
                <span className="emphasized-amts">
                  {repayments} {repayments === 1 ? "month" : "months"}
                </span>
                .
              </p>
              <p className="kuda-cta-sm mb-4 narration disclaimer">
                This repayment amount is an estimate. You'll get an exact amount
                when you apply for a loan. Terms and Conditions apply.
              </p>
            </div>
            <SummaryDetailsWrap>
              <LoanDetails className="kuda-result--item">
                <p>Principal</p>
                <p className="loan-principal">₦{formatNumber(loanAmount)}</p>
              </LoanDetails>
              <LoanDetails className="kuda-result--item">
                <p>Interest</p>
                <p className="loan-figures">₦{formatNumber(totalInterest)}</p>
              </LoanDetails>
              <LoanDetails className="kuda-result--item">
                <p>Monthly repayment</p>
                <p className="loan-figures">
                  ₦{formatNumber(monthlyRepayment)}
                </p>
              </LoanDetails>
              <LoanDetails className="kuda-result--item">
                <p>Monthly Interest</p>
                <p className="loan-figures">{interestRate}%</p>
              </LoanDetails>
              <LoanDetails className="kuda-result--item mb-5">
                <p>Number of Repayments</p>
                <p className="loan-figures">{repayments}</p>
              </LoanDetails>
            </SummaryDetailsWrap>
            <div id="end-view" className="text-center">
              <Button
                text="Apply For A Loan"
                width="335px"
                onButtonClick={handleNextClick}
                className={"mb-4"}
              />
            </div>
          </OutputWrap>
        </div>
      </LoanCalcWrap>
      <FAQList title={"Loan Calculator FAQs"} questions={questions} />
      <CTAInvestments />
    </Fragment>
  )
}

export default PersonalLoanCalculator
